import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import useSWR from 'swr';
import { useState as useGlobalState } from 'state-pool';
import classnames from 'classnames';
import { Button, IconCustom, Link, LoadingSpinner, Markdown, MyAccountHeadline, SeparationLine, Slider } from '@engbers/components';
import Price from '@engbers/components/online-shops/price';
import { PdpSizesModal } from '@engbers/components/online-shops/product-details-page/pdp-info/pdp-sizes/sizesModal';
import { fetchApiHub, GlobalStateContext, useAccount, useCart, useWishlist } from '@frontastic-engbers/lib';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { Reference } from '@frontastic-engbers/helpers/reference';
import { disableScrollOnBody } from '@frontastic-engbers/helpers/utils/disableScrollOnBody';
import { sortSizes } from '@frontastic-engbers/helpers/utils/sizeSorting';
import { EcondaWidgetConfiguration, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { LineItem } from '@frontastic-engbers/types/wishlist/LineItem';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Product } from '@frontastic-engbers/types/product/Product';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { getVisitorId } from '@frontastic-engbers/helpers/dataLayerHelper/econdaDataHelper';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import styles from './my-wishlist.module.scss';
export type Wishlist = {
  headlineText?: string;
  textSize?: number;
  headlineIcon?: MediaType;
  removeFromWishlistIcon?: MediaType;
  addToBasketIcon?: MediaType;
  sizeInformationText?: string;
  sizeInformationLink?: Reference;
  deliveryTime?: string;
  priceDeliveryHint?: string;
  shippingCostsModalText?: string;
  moreDetails?: string;
  addToBasketText?: string;
  notBuyableText?: string;
  emptyWishlistMarkdown?: string;
  econdaWidgetConfiguration: EcondaWidgetConfiguration;
};
export const MyAccountWishlist: React.FC<Wishlist> = ({
  headlineText,
  textSize,
  headlineIcon,
  removeFromWishlistIcon,
  addToBasketIcon,
  sizeInformationText,
  sizeInformationLink,
  deliveryTime,
  priceDeliveryHint,
  shippingCostsModalText,
  moreDetails,
  addToBasketText,
  notBuyableText,
  emptyWishlistMarkdown,
  econdaWidgetConfiguration
}) => {
  const {
    data
  } = useWishlist();
  const [showAmountBubble, setShowAmountBubble] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (data && data.lineItems.length >= 1) {
      setShowAmountBubble(true);
    }
  }, [data?.lineItems?.length]);
  if (!data?.lineItems) {
    return null;
  }
  return <div className={styles.wishlist} data-sentry-component="MyAccountWishlist" data-sentry-source-file="index.tsx">
      <LoadingSpinner isLoading={isLoading} data-sentry-element="LoadingSpinner" data-sentry-source-file="index.tsx" />
      <MyAccountHeadline headlineText={headlineText} textSize={textSize} iconMedia={headlineIcon} iconCustom="Heart" uppercase amountBubble={data.lineItems.length} showAmountBubble={showAmountBubble} data-sentry-element="MyAccountHeadline" data-sentry-source-file="index.tsx" />
      {data.lineItems.length === 0 ? <Markdown text={emptyWishlistMarkdown} className={styles.markdown} /> : <div className="mt-8">
          {data.lineItems.map((item, index) => <WishlistItem item={item} removeFromWishlistIcon={removeFromWishlistIcon} addToBasketIcon={addToBasketIcon} popupTexts={{
        sizeInformationText,
        sizeInformationLink,
        deliveryTime,
        priceDeliveryHint,
        shippingCostsModalText,
        moreDetails,
        addToBasketText,
        notBuyableText
      }} key={index} econdaWidgetConfiguration={econdaWidgetConfiguration} setIsLoading={setIsLoading} />)}
        </div>}
    </div>;
};
type PopupTexts = {
  sizeInformationText: string;
  sizeInformationLink: Reference;
  deliveryTime: string;
  priceDeliveryHint: string;
  shippingCostsModalText?: string;
  moreDetails: string;
  addToBasketText: string;
  notBuyableText: string;
};
type WishlistItemProps = {
  item: LineItem;
  removeFromWishlistIcon?: MediaType;
  addToBasketIcon?: MediaType;
  popupTexts: PopupTexts;
  econdaWidgetConfiguration: EcondaWidgetConfiguration;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
const WishlistItem: React.FC<WishlistItemProps> = ({
  item,
  removeFromWishlistIcon,
  addToBasketIcon,
  popupTexts,
  econdaWidgetConfiguration,
  setIsLoading
}) => {
  const {
    getTitle
  } = useImageSEO();
  const {
    removeLineItem
  } = useWishlist();
  const {
    account
  } = useAccount();
  const [showAddToBasketPopup, setShowAddToBasketPopup] = useState<boolean>(false);
  const isKit = item.variant.sku.includes('KIT');
  const handleRemoveFromWishlist = async () => {
    setIsLoading(true);
    await removeLineItem(item.lineItemId);
    new TagManager().econdaWishlistEvent({
      id: item.variant.sku.split(/[-.]/)[0],
      quantity: 1,
      date: new Date().toISOString().replace('T', ' ').split('.')[0]
    }, 'econdaRemoveFromWishlist', 'rmv_wish', getVisitorId(account)).executePush();
    setIsLoading(false);
  };
  const handleAddToCart = isKit ? undefined : () => {
    setShowAddToBasketPopup(true);
    disableScrollOnBody(true);
  };
  const addToCartButton = addToBasketIcon?.media?.file ? <div className={styles.addToBasket} onClick={handleAddToCart}>
      <img src={addToBasketIcon.media.file} alt={getTitle(addToBasketIcon)} title={getTitle(addToBasketIcon)} />
    </div> : <IconCustom icon="CartAdd" className={styles.addToBasket} width={46} // 22 + 2x12px padding
  onClick={handleAddToCart} />;
  return <div className={styles.item} data-sentry-component="WishlistItem" data-sentry-source-file="index.tsx">
      <div className={styles.image}>
        <Link href={item._url} data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <img src={item.variant.images[0]} alt={item.name} title={item.name} />
        </Link>
      </div>
      <div className={styles.details}>
        <Link href={item._url} data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <span className={styles.designerName}>{item.variant.attributes.DesignerName}</span>
          <br />
          <span className={styles.itemName}>{item.name}</span>
        </Link>
        {!item.variant.sku.includes('KIT') && <Price className={styles.price} price={item.variant.price} discountedPrice={item.variant.discountedPrice} />}
      </div>
      <div className={styles.buttons}>
        {removeFromWishlistIcon?.media?.file ? <div className={styles.removeFromWishlist} onClick={handleRemoveFromWishlist}>
            <img src={removeFromWishlistIcon.media.file} alt={getTitle(removeFromWishlistIcon)} title={getTitle(removeFromWishlistIcon)} />
          </div> : <IconCustom icon="TrashBasket" className={styles.removeFromWishlist} width={46} // 22 + 2x12px padding
      onClick={handleRemoveFromWishlist} />}
        {isKit ? <Link href={item._url}>{addToCartButton}</Link> : addToCartButton}
        {!isKit && <WishlistBackdrop item={item} show={showAddToBasketPopup} closePopup={() => {
        setShowAddToBasketPopup(false);
        disableScrollOnBody(false);
      }} popupTexts={popupTexts} econdaWidgetConfiguration={econdaWidgetConfiguration} />}
      </div>
    </div>;
};
type WishlistBackdropProps = {
  item: LineItem;
  show: boolean;
  closePopup: () => void;
  popupTexts: PopupTexts;
  econdaWidgetConfiguration: EcondaWidgetConfiguration;
};
const WishlistBackdrop: React.FC<WishlistBackdropProps> = ({
  item,
  show,
  closePopup,
  popupTexts,
  econdaWidgetConfiguration
}) => {
  // eslint-disable-next-line react/display-name
  const Backdrop = forwardRef<HTMLDivElement>((props, ref) => {
    const handleBackdropClick = event => {
      if (event.target.classList.contains(styles.wishlistPopupWrap)) {
        closePopup();
      }
    };
    return <div className={styles.backdrop} onClick={handleBackdropClick} ref={ref} {...props} />;
  });
  return createPortal(<Transition as={Backdrop} show={show}>
      <Transition.Child className={classnames('bg-black/40', styles.wishlistPopupWrap)} />
      <WishlistPopup item={item} closePopup={closePopup} popupTexts={popupTexts} econdaWidgetConfiguration={econdaWidgetConfiguration} />
    </Transition>, document.body);
};
type WishlistPopupProps = {
  item: LineItem;
  closePopup: () => void;
  popupTexts: PopupTexts;
  econdaWidgetConfiguration: EcondaWidgetConfiguration;
};
const WishlistPopup: React.FC<WishlistPopupProps> = ({
  item,
  closePopup,
  popupTexts,
  econdaWidgetConfiguration
}) => {
  const {
    removeLineItem
  } = useWishlist();
  const {
    data: cart,
    addItem
  } = useCart();
  const {
    account
  } = useAccount();
  const {
    pushNotification
  } = useToastNotificationsActions();
  const [loading, setLoading] = useState<boolean>(false);
  const [, setGlobalState] = useGlobalState(GlobalStateContext);
  const [selectedSKU, setSelectedSKU] = useState<string>();
  const [selectedSKUError, setSelectedSKUError] = useState<boolean>(false);
  const [product, setSelectedProduct] = useState<Product>(null);
  const [selectedVariant, setSelectedVariant] = useState<Variant>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const priceDeliveryHintRef = useRef<HTMLDivElement>(null);
  const {
    formatMessage
  } = useFormat({
    name: 'account'
  });
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    data: sizeData
  } = useSWR(`/action/product/getSizeVariantsById?id=${item.productId}`, fetchApiHub);
  const sizes = sizeData?.sort((a: Variant, b: Variant) => sortSizes({
    value: a.attributes.Sizing
  }, {
    value: b.attributes.Sizing
  }));
  const {
    data: outfitProduct
  } = useSWR(`/action/product/getProduct?id=${item.variant?.attributes?.OutfitMainItem_2}`, fetchApiHub);
  const title = `${item.variant.attributes.DesignerName} ${item.name}`;
  const {
    sizeInformationText,
    sizeInformationLink,
    deliveryTime,
    priceDeliveryHint,
    shippingCostsModalText,
    moreDetails,
    addToBasketText,
    notBuyableText
  } = popupTexts;
  const preventGiftCardBuy = item.variant.attributes.IsGiftcard && cart.customLineItems.length > 0;
  useEffect(() => {
    if (priceDeliveryHintRef.current) {
      const link = priceDeliveryHintRef.current.querySelector('a');
      if (link) {
        link.addEventListener('click', e => e.preventDefault());
        link.addEventListener('mouseover', () => setShowTooltip(true));
        link.addEventListener('mouseleave', () => setShowTooltip(false));
        return () => {
          link.removeEventListener('click', e => e.preventDefault());
          link.removeEventListener('mouseover', () => setShowTooltip(true));
          link.removeEventListener('mouseleave', () => setShowTooltip(false));
        };
      }
    }
  }, [priceDeliveryHintRef]);
  const handleAddToCart = async () => {
    if (selectedSKU) {
      setLoading(true);
      addItem({
        sku: selectedSKU,
        attributes: {
          IsGiftcard: item.variant.attributes.IsGiftcard
        }
      }, 1).then(async response => {
        if (typeof response === 'string') {
          closePopup();
          pushNotification(response, 'error');
          return;
        }
        new TagManager().addToCartEvent([ItemMapper.commercetoolsProductToItem(product, selectedVariant, 1)], ItemMapper.convertCentAmount(selectedVariant.price)).econdaCartEvent(product, selectedVariant, 'econdaAddToCart', 'c_add').econdaWishlistToCart(product, selectedVariant, getVisitorId(account)).executePush();
        await removeLineItem(item.lineItemId);
        closePopup();
        setGlobalState({
          isFlyoutCartOpen: true,
          flyoutAlternativeProductsConfig: outfitProduct?.productId ? outfitProduct : econdaWidgetConfiguration ? {
            ...econdaWidgetConfiguration,
            sku: selectedSKU
          } : undefined
        });
      });
      setLoading(false);
    } else {
      setSelectedSKUError(true);
    }
  };
  return <div className={styles.popup} data-sentry-component="WishlistPopup" data-sentry-source-file="index.tsx">
      <div className={styles.close} onClick={closePopup}>
        <IconCustom icon="PinchClose" width={26} data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
        <div className={styles.text}>
          {formatMessage({
          id: 'wishlist.popup.close',
          defaultMessage: 'Schließen'
        })}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.productImage}>
          <Slider slidesPerView={1} arrows={true} dots={false} thumbsSwiperProps={{
          spaceBetween: 5,
          navigation: true,
          slidesPerView: 'auto'
        }} withThumbs data-sentry-element="Slider" data-sentry-source-file="index.tsx">
            {item.variant.images.map((url, i) => <img src={url} alt={title} title={title} key={url} />)}
          </Slider>
        </div>
        <div className={styles.productInfo}>
          <h3>
            <div>{item.variant.attributes.DesignerName}</div>
            <div>{item.name}</div>
          </h3>
          <span className={styles.productNumber}>
            {formatMessage({
            id: 'wishlist.popup.productNumber',
            defaultMessage: 'Artikelnummer: {productNumber}',
            values: {
              productNumber: getSKUParts(item.variant.sku).key
            }
          })}
          </span>
          <div className={styles.sizesModal}>
            {sizes && <PdpSizesModal handleAddToCart={async sku => {
            const product = await fetchApiHub(`/action/product/getProduct?sku=${sku}`);
            const currentVariant = product?.variants?.find(variant => variant.sku === sku);
            setSelectedSKU(sku);
            setSelectedProduct(product);
            setSelectedVariant(currentVariant);
            setSelectedSKUError(false);
          }} variants={sizes} isGiftCard={item.variant.attributes.IsGiftcard} />}
          </div>
          {selectedSKUError && <div className={styles.selectedSizeError}>
              {item.variant.attributes.IsGiftcard ? formatErrorMessage({
            id: 'giftCardWishlistSelectedValue',
            defaultMessage: 'Bitte wählen Sie Ihren Wert aus.'
          }) : formatErrorMessage({
            id: 'wishlistSelectedSize',
            defaultMessage: 'Bitte wählen Sie Ihre Größe aus.'
          })}
            </div>}
          {!item.variant.attributes.IsGiftcard && <div className={styles.sizeInformation}>
              <Link reference={sizeInformationLink} onClick={closePopup}>
                {sizeInformationText}
              </Link>
            </div>}
          <SeparationLine sizeTop={10} sizeBottom={20} height={1} data-sentry-element="SeparationLine" data-sentry-source-file="index.tsx" />
          {!item.variant.attributes.IsGiftcard && <span className={styles.deliveryTime}>{deliveryTime}</span>}
          {!selectedVariant && item.variant?.attributes?.IsGiftcard ? <div className="text-2xl-bold mb-2 mt-7 flex items-center gap-2">
              {formatMessage({
            id: 'price.from',
            defaultMessage: 'ab'
          })}
              <Price price={item.variant.price} isBold />
            </div> : <Price price={selectedVariant ? selectedVariant.price : item.variant.price} discountedPrice={selectedVariant ? selectedVariant.discountedPrice : item.variant.discountedPrice} className={styles.popupPrice} />}
          <div ref={priceDeliveryHintRef} className={styles.priceDeliveryHint}>
            <Markdown text={priceDeliveryHint} data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />

            {showTooltip && <div className={styles.shippingCostsTooltip}>
                <Markdown text={shippingCostsModalText} className={styles.shippingCostsTooltipText} />
              </div>}
          </div>
          <SeparationLine sizeTop={10} sizeBottom={20} height={1} data-sentry-element="SeparationLine" data-sentry-source-file="index.tsx" />
          <Link href={item._url} className={styles.moreDetails} onClick={closePopup} data-sentry-element="Link" data-sentry-source-file="index.tsx">
            {moreDetails}
          </Link>
          <Button className={styles.addToBasketButton} type="cta" label={addToBasketText} onClick={handleAddToCart} isLoading={loading} disabled={selectedSKUError || preventGiftCardBuy} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
          {preventGiftCardBuy && notBuyableText && <span className={styles.notBuyableText}>{notBuyableText}</span>}
        </div>
      </div>
    </div>;
};