import Link from 'next/link';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import ProductSlider from '../../product-slider';
import { Image, WishlistButton } from '@engbers/components';
import styles from './pdp-outfit-slider.module.scss';
import React from 'react';
export interface IPdpOutfitSlider {
  outfit?: Outfit;
  outfitSliderHeadline: string;
  textSize?: number;
}
export const PdpOutfitSlider: React.FC<IPdpOutfitSlider> = ({
  outfit,
  outfitSliderHeadline,
  textSize
}) => {
  const mainVariant = outfit?.variants?.[0];
  const products = outfit?.products ?? [];
  if (!mainVariant?.sku || !mainVariant?.images?.[0] || products.length === 0) {
    return null;
  }
  return <div className={styles.outfitSliderWrap} data-sentry-component="PdpOutfitSlider" data-sentry-source-file="index.tsx">
      <div className={styles.outfitMainLink}>
        {mainVariant.sku && <WishlistButton sku={mainVariant.sku} wrapperClassName={styles.WishlistButton} />}
        <Link href={outfit.url} legacyBehavior={false} data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <Image src={mainVariant.images[0]} alt={outfit.name} title={outfit.name} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </Link>
      </div>
      <div className={styles.sliderWrap}>
        <ProductSlider products={products} title={outfitSliderHeadline} textSize={textSize} slidesPerView={{
        mobile: 2.5,
        desktop: 3
      }} data-sentry-element="ProductSlider" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};