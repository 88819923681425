import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { useCart } from '@frontastic-engbers/lib/provider';
import { useSnippets } from '@frontastic-engbers/lib/actions/config';
import { CheckoutData, CheckoutErrorsType, CustomerData, CustomerOrigin, ForbiddenFields, LocationSearchState, MediaType, PackstationInput, ShippingType } from '@frontastic-engbers/types/engbers-custom';
import { ProjectSettings } from '@frontastic-engbers/types/ProjectSettings';
import { Address as AddressType } from '@frontastic-engbers/types/account/Address';
import { ShippingMethod } from '@frontastic-engbers/types/cart/ShippingMethod';
import { getTaxedCountries } from '@frontastic-engbers/helpers/utils/getTaxedCountries';
import { getFormattedShippingCostsByCountry, getSelectedShippingAddress } from '@frontastic-engbers/helpers/utils/getShippingInfo';
import { Image } from '@engbers/components';
import { Address } from '../../address';
import { PackStation } from '../../packstation';
import { StoreDelivery } from '../../store-delivery';
import styles from './shipping-address.module.scss';
import { mapCosts } from '@frontastic-engbers/helpers/utils/mapCosts';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
interface IShippingAddressSection {
  updateFormInput: (propName: string, newValue: string | AddressType | CustomerData | boolean) => void;
  updateCartData: (validate: boolean, checkoutDataWithPayment?: CheckoutData) => Promise<void>;
  labels: {
    [name: string]: string;
  };
  data: CheckoutData;
  shippingAddressSection: {
    headlineShippingAddress: string;
    textShippingAddress: string;
  };
  packStationSection: {
    headlinePackstation: string;
    labelPostNo: string;
    labelSelectPackstation: string;
    textPackstation: string;
    errorPackstation: string;
    errorPostNo: string;
  };
  storeDeliverySection: {
    headlineStoreDelivery: string;
    textStoreDelivery: string;
    labelOpeningHours: string;
    labelSelectStoreDelivery: string;
    errorStoreDelivery: string;
    storeClosed: string;
  };
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  shippingSection: {
    headline: string;
    hasStoreDelivery: boolean;
    hasPackStationDelivery: boolean;
    billingAddressBoxIcon: MediaType;
    billingAddressBoxLabel: string;
    billingAddressDeliveryCosts: string;
    shippingAddressBoxIcon: MediaType;
    shippingAddressBoxLabel: string;
    shippingAddressDeliveryCosts: string;
    storeDeliveryBoxIcon: MediaType;
    storeDeliveryBoxLabel: string;
    storeDeliveryCosts: string;
    packStationBoxIcon: MediaType;
    packStationBoxLabel: string;
    packStationDeliveryCosts: string;
  };
  forbiddenFields: ForbiddenFields;
  checkoutErrors: CheckoutErrorsType;
  setCheckoutErrors: React.Dispatch<React.SetStateAction<CheckoutErrorsType>>;
  loggedIn: boolean;
}
export const ShippingAddress: React.FC<IShippingAddressSection> = ({
  shippingSection,
  updateFormInput,
  updateCartData,
  labels,
  data,
  shippingAddressSection,
  packStationSection,
  storeDeliverySection,
  setLoading,
  loading,
  forbiddenFields,
  checkoutErrors,
  setCheckoutErrors,
  loggedIn
}) => {
  const customerOrigin: CustomerOrigin = {
    isGuest: false,
    isNew: false
  };
  const {
    formatMessage
  } = useFormat({
    name: 'checkout'
  });
  const {
    getTitle
  } = useImageSEO();
  const {
    data: cart,
    getProjectSettings,
    getShippingMethods,
    getShippingMethodById
  } = useCart();
  const {
    snippetUnselectedShippingAddressHint
  } = useSnippets();
  const [projectSettings, setProjectSettings] = useState<ProjectSettings>(null);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>(null);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<ShippingMethod>(null);
  const [availableCountryOptions, setAvailableCountryOptions] = useState<string[]>(null);
  const [packstationState, setPackstationState] = useState<LocationSearchState>({
    locations: [],
    showAll: false,
    searchData: {
      streetName: '',
      streetNumber: '',
      postalCode: '',
      city: ''
    }
  });
  const [storeDeliveryState, setStoreDeliveryState] = useState<LocationSearchState>({
    locations: [],
    showAll: false,
    searchData: {
      streetName: '',
      streetNumber: '',
      postalCode: '',
      city: ''
    }
  });
  const [packstationInputs, setPackstationInputs] = useState<{
    [key: string]: PackstationInput;
  }>({});
  useEffect(() => {
    getShippingMethods().then(data => {
      setShippingMethods(data);
    });
    getProjectSettings().then(data => {
      setProjectSettings(data);
    });
  }, []);
  useEffect(() => {
    if (shippingMethods && projectSettings) {
      const countries = getTaxedCountries(shippingMethods, projectSettings?.countries);
      setAvailableCountryOptions(countries);
    }
  }, [shippingMethods, projectSettings]);
  useEffect(() => {
    const fetchById = async () => {
      const shippingMethod = await getShippingMethodById(cart?.availableShippingMethods[0].shippingMethodId);
      setSelectedShippingMethod(shippingMethod);
    };
    if (cart?.availableShippingMethods) {
      fetchById();
    }
  }, [cart?.availableShippingMethods]);
  const selectShippingMethod = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setLoading(true);
      const shippingMethod = e.target.name as ShippingType;
      await updateCartData(false, {
        ...data,
        shippingMethod,
        ...(shippingMethod === 'shippingAddress' && {
          shippingAddress: {
            ...data.shippingAddress,
            country: data.billingAddress.country
          }
        })
      });
      if (shippingMethod === 'shippingAddress') {
        updateFormInput('shippingAddress', {
          ...data.shippingAddress,
          country: data.billingAddress.country
        });
      }
      updateFormInput('shippingMethod', shippingMethod);
      updateFormInput('isUnselectedShippingAddress', false);
      setLoading(false);
    }
  };
  const shippingMethodBox = (methodName: string, icon: MediaType, label: string, price: string, priceFallback?: string, free?: boolean) => {
    return <div className={styles.shippingMethodBoxWrapper} data-sentry-component="shippingMethodBox" data-sentry-source-file="index.tsx">
        <label className={classnames(styles.shippingMethodBox, methodName === data.shippingMethod ? styles.isChecked : undefined)} htmlFor={methodName}>
          <input id={methodName} name={methodName} type="checkbox" checked={methodName === data.shippingMethod} className={styles.shippingMethodBoxWrap} onChange={selectShippingMethod} />
          <Image src={icon?.media?.file} width={40} alt={getTitle(icon)} title={getTitle(icon)} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
          <div className={styles.shippingMethodBoxTextWrap}>
            <div className={styles.shippingMethodBoxLabel}>{label}</div>
            <div className={classnames(methodName === 'store' || free ? styles.isFree : undefined)}>
              {free ? formatMessage({
              id: 'freeShipping',
              defaultMessage: 'kostenlos'
            }) : priceFallback && (!price || price === '') ? priceFallback : price}
            </div>
          </div>
        </label>
      </div>;
  };
  const costs = useMemo(() => mapCosts(cart, getSelectedShippingAddress(data)?.country), [cart]);
  return <div data-sentry-component="ShippingAddress" data-sentry-source-file="index.tsx">
      {checkoutErrors && !checkoutErrors['shippingAddress']?.isValid && data.isUnselectedShippingAddress && <div className={classnames('mb-3', styles.errorMessage)}>{snippetUnselectedShippingAddressHint}</div>}
      <div className={styles.shippingMethodsWrap}>
        {shippingMethodBox('billingAddress', shippingSection.billingAddressBoxIcon, shippingSection.billingAddressBoxLabel, getFormattedShippingCostsByCountry(selectedShippingMethod, data.billingAddress.country), shippingSection.billingAddressDeliveryCosts, costs.shipping.centAmount === 0)}
        {shippingMethodBox('shippingAddress', shippingSection.shippingAddressBoxIcon, shippingSection.shippingAddressBoxLabel, getFormattedShippingCostsByCountry(selectedShippingMethod, data.shippingAddress.country), shippingSection.shippingAddressDeliveryCosts, costs.shipping.centAmount === 0)}
        {data.shippingMethod === 'shippingAddress' && availableCountryOptions ? <div className={styles.addressFormWrap}>
            <span className={styles.addressFormHeadline}>{shippingAddressSection.headlineShippingAddress}</span>
            <span className={styles.addressFormText}>{shippingAddressSection.textShippingAddress}</span>
            <Address data={data} updateFormInput={updateFormInput} labels={labels} isShipping={true} countries={availableCountryOptions} customerOrigin={customerOrigin} forbiddenFields={forbiddenFields} setCheckoutErrors={setCheckoutErrors} loggedIn={loggedIn} />
          </div> : null}
        {shippingSection.hasStoreDelivery ? shippingMethodBox('store', shippingSection.storeDeliveryBoxIcon, shippingSection.storeDeliveryBoxLabel, shippingSection.storeDeliveryCosts, null, costs.shipping.centAmount === 0) : null}
        {data.shippingMethod === 'store' ? <div className={styles.addressFormWrap}>
            <span className={styles.addressFormHeadline}>{storeDeliverySection.headlineStoreDelivery}</span>
            <span className={styles.addressFormText}>{storeDeliverySection.textStoreDelivery}</span>
            <StoreDelivery setLoading={setLoading} loading={loading} errorMessage={storeDeliverySection.errorStoreDelivery} labelOpeningHours={storeDeliverySection.labelOpeningHours} labelSelect={storeDeliverySection.labelSelectStoreDelivery} storeClosedText={storeDeliverySection.storeClosed} shippingAddress={data.storeDeliveryAddress} useStoreDelivery={{
          storeDeliveryState,
          setStoreDeliveryState
        }} labels={labels} updateFormInput={updateFormInput} />
          </div> : null}
        {shippingSection.hasPackStationDelivery ? shippingMethodBox('packStation', shippingSection.packStationBoxIcon, shippingSection.packStationBoxLabel, shippingSection.packStationDeliveryCosts, null, costs.shipping.centAmount === 0) : null}
        {data.shippingMethod === 'packStation' ? <div className={styles.addressFormWrap}>
            <span className={styles.addressFormHeadline}>{packStationSection.headlinePackstation}</span>
            <span className={styles.addressFormText}>{packStationSection.textPackstation}</span>
            <PackStation setLoading={setLoading} loading={loading} errorMessage={packStationSection.errorPackstation} errorPostNo={packStationSection.errorPostNo} labelPostNo={packStationSection.labelPostNo} labelSelect={packStationSection.labelSelectPackstation} shippingAddress={data.packstationAddress} usePackstation={{
          packstationState,
          setPackstationState
        }} usePackstationInputs={{
          packstationInputs,
          setPackstationInputs
        }} labels={labels} updateFormInput={updateFormInput} />
          </div> : null}
      </div>
    </div>;
};