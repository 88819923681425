import { Reference, ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { TMediaItem } from '@frontastic-engbers/types/engbers-custom';
import React, { useMemo } from 'react';
import { default as ImageComponent, MediaItem } from '@frontastic-engbers/lib/lib/image';
interface IImage {
  src?: string;
  media?: TMediaItem;
  width?: string | number;
  link?: Reference;
  alt?: string;
  title?: string;
  isFullWidth?: boolean;
  loading?: 'eager' | 'lazy';
  qualityOverwrite?: number;
}

/*
  The image component takes care of rendering images
  Most importantly it will try to use the frontastic image component (which makes use of the next image component)
 */
export const Image: React.FC<IImage> = ({
  src,
  media,
  width,
  link,
  alt,
  title,
  isFullWidth,
  loading = 'lazy',
  qualityOverwrite
}) => {
  const renderedImageComponent = useMemo(() => {
    if (media) {
      return <ImageComponent alt={alt || ''} title={title} quality={qualityOverwrite || 80} media={media as MediaItem} loading={loading} width={width} />;
    } else {
      return <img alt={alt || ''} title={title} width={width} loading={loading} src={media?.file || src} style={isFullWidth ? {
        width: '100%',
        objectFit: 'cover'
      } : undefined} />;
    }
  }, [alt, title, width, media, isFullWidth]);
  return <div style={{
    width: width
  }} className="custom-image-component" data-sentry-component="Image" data-sentry-source-file="index.tsx">
      {link ? <ReferenceLink target={link} className="w-full">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          {renderedImageComponent}
        </ReferenceLink> : renderedImageComponent}
    </div>;
};