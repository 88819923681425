import React from 'react';
import { IconCustom, Markdown } from '@engbers/components';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import styles from './size-doubled-overview.module.scss';
export interface ISizeDoubledOverview {
  overviewDoubledSizeMessage: string;
  lineItems: LineItem[];
}
export const SizeDoubledOverview: React.FC<ISizeDoubledOverview> = ({
  overviewDoubledSizeMessage,
  lineItems
}) => {
  const skuArray = [];
  let doubled = false;
  if (lineItems) {
    for (const lineItem of lineItems) {
      const sku = getSKUParts(lineItem.variant.sku).key;
      const found = skuArray.find(x => x.sku === sku);
      if (found) {
        doubled = true;
      }
      skuArray.push({
        sku
      });
    }
  }
  if (!doubled) {
    return null;
  }
  return <div className={styles.sizeInfoMessage} data-sentry-component="SizeDoubledOverview" data-sentry-source-file="index.tsx">
      <IconCustom width={20} icon="Info" color={'primary'} data-sentry-element="IconCustom" data-sentry-source-file="index.tsx" />
      <Markdown text={overviewDoubledSizeMessage} className={styles.markdown} linkToNewTab data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
    </div>;
};