import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { MELEVEN_BASE_URL } from '@frontastic-engbers/helpers/constants/meleven';
import { useProductOutfitTitle } from '@frontastic-engbers/helpers/seoHelper';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Image } from '@engbers/components';
import styles from './pdp-description.module.scss';
export interface IPdpDescription {
  className?: string;
  product: Product;
  variant: Variant;
}
export const PdpDescription: React.FC<IPdpDescription> = ({
  className,
  product,
  variant
}) => {
  const {
    images
  } = variant;
  const attributes = {
    ...product.attributes,
    ...variant.attributes
  };
  const {
    formatMessage
  } = useFormat({
    name: 'product'
  });
  const {
    formatMessage: cartFormatMessage
  } = useFormat({
    name: 'cart'
  });
  const {
    title
  } = useProductOutfitTitle({
    product,
    attributes
  });
  const CareLabelIcon = (content: string, tooltipId: string): React.ReactElement<HTMLDivElement> => {
    const [icon, label] = content.split(';');
    return <div className={styles.pdpDescLabelCareWrap} data-tooltip-class-name={styles.pdpDescTooltipWrap} data-tooltip-variant="light" data-tooltip-id={tooltipId} data-sentry-component="CareLabelIcon" data-sentry-source-file="index.tsx">
        <Image src={`${MELEVEN_BASE_URL}${icon}`} alt={label} title={label} width={25} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <Tooltip id={tooltipId} content={label} data-sentry-element="Tooltip" data-sentry-source-file="index.tsx" />
      </div>;
  };
  return <div className={classnames(className, styles.pdpDescription)} data-sentry-component="PdpDescription" data-sentry-source-file="index.tsx">
      <div className={styles.pdpDescSection}>
        <div className={styles.pdpDescInfo}>
          <h2 className={styles.pdpDescTitle}>
            {formatMessage({
            id: 'product.desc',
            defaultMessage: 'Produktbeschreibung'
          })}
          </h2>
          <ul>
            <li>
              <span>
                {formatMessage({
                id: 'typeNumber',
                defaultMessage: 'Art.-Nr.'
              })}
                :
              </span>
              <span>{product.productId}</span>
            </li>
            {attributes.FormDescription && <li>
                <span>
                  {formatMessage({
                id: 'form',
                defaultMessage: 'Form'
              })}
                  :
                </span>
                <span>{attributes.FormDescription}</span>
              </li>}
            {!attributes.IsGiftcard && !attributes.IsFreeShipping && <li>
                <span>
                  {cartFormatMessage({
                id: 'deliveryTime',
                defaultMessage: 'Lieferzeit'
              })}
                  :
                </span>
                <span>
                  {cartFormatMessage({
                id: 'deliveryTimeValueDE',
                defaultMessage: '2-4 Werktage'
              })}
                </span>
              </li>}
          </ul>
          <ul>
            {attributes.Keyfeature1 && <li>{attributes.Keyfeature1}</li>}
            {attributes.Keyfeature2 && <li>{attributes.Keyfeature2}</li>}
            {attributes.Keyfeature3 && <li>{attributes.Keyfeature3}</li>}
          </ul>
        </div>

        {product.description && <div>{product.description}</div>}

        <div className={styles.pdpDescMaterial}>
          <strong className={styles.pdpDescTitle}>
            {formatMessage({
            id: 'product.material',
            defaultMessage: 'Material & Pflegehinweis'
          })}
          </strong>
          {attributes.Material && <div className={styles.pdpDescMaterialName}>{attributes.Material}</div>}
          <div className="flex items-center gap-3">
            {attributes.CareLabelIroning && CareLabelIcon(attributes.CareLabelIroning, 'careLabelIroning')}
            {attributes.CareLabelCleaning && CareLabelIcon(attributes.CareLabelCleaning, 'careLabelCleaning')}
            {attributes.CareLabelBleach && CareLabelIcon(attributes.CareLabelBleach, 'careLabelBleach')}
            {attributes.CareLabelWash && CareLabelIcon(attributes.CareLabelWash, 'careLabelWash')}
            {attributes.CareLabelDry && CareLabelIcon(attributes.CareLabelDry, 'careLabelDry')}
          </div>
        </div>
      </div>

      <div className={classnames('hidden gap-2 lg:flex', styles.pdpDescSection)}>
        {images[2] && <Image src={images[2]} alt={title} title={title} width={310} />}
        {images[3] && <Image src={images[3]} alt={title} title={title} width={310} />}
      </div>
    </div>;
};