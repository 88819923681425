import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { ITable, Table } from '@engbers/components/online-shops/table';
import { Image } from '@engbers/components';
import React from 'react';
export interface ISizeAdvisorModal {
  productGroup: string;
  sizeAdvisorTables: {
    sizeAdvisorTableProductGroup: string;
    sizeAdvisorImage: MediaType;
    sizeTables: ITable[];
  }[];
}
export const SizeAdvisorModal: React.FC<ISizeAdvisorModal> = ({
  productGroup,
  sizeAdvisorTables
}) => {
  const {
    getTitle
  } = useImageSEO();
  const productGroupTables = sizeAdvisorTables.filter(sizeTables => sizeTables.sizeAdvisorTableProductGroup === productGroup);
  if (!productGroupTables?.length) {
    return null;
  }
  return <div data-sentry-component="SizeAdvisorModal" data-sentry-source-file="index.tsx">
      {productGroupTables.map((table, i) => <div key={`size-group-${i}`}>
          {table.sizeAdvisorImage && <div className="mb-8">
              <Image src={table.sizeAdvisorImage?.media?.file} alt={getTitle(table.sizeAdvisorImage)} title={getTitle(table.sizeAdvisorImage)} />
            </div>}
          {table && table.sizeTables.map((sizeTable, index) => <Table key={`size-table-${index}`} tableHeadline={sizeTable.tableHeadline} tableColumns={sizeTable.tableColumns} isSizeAdvisorTable />)}
        </div>)}
    </div>;
};