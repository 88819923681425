import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { EcondaWidgetConfiguration } from '@frontastic-engbers/types/engbers-custom';
import { ProductDetailsPage } from '@engbers/components/online-shops/product-details-page';
const pdpColorVariantCache = {};
/**
 * Ensures product variants have properly mapped colorVariants (if available).
 * Uses {@link pdpColorVariantCache} to cache and transfer colorVariants by id.
 * Only intended for PDP with product data from Commercetools.
 * Intentionally works with the reference of objects/arrays.
 * @param {Product} product
 */
const runVariantsAgainstCache = (product: Product) => {
  product.attributes.ColorVariants?.forEach(colorVariant => {
    if (colorVariant.obj) {
      if (!pdpColorVariantCache[colorVariant.id]) {
        pdpColorVariantCache[colorVariant.id] = colorVariant.obj;
      }
    } else if (pdpColorVariantCache[colorVariant.id]) {
      colorVariant.obj = pdpColorVariantCache[colorVariant.id];
    }
  });
  product.variants.forEach(variant => {
    variant.attributes.ColorVariants?.forEach(colorVariant => {
      if (colorVariant.obj) {
        if (!pdpColorVariantCache[colorVariant.id]) {
          pdpColorVariantCache[colorVariant.id] = colorVariant.obj;
        }
      } else if (pdpColorVariantCache[colorVariant.id]) {
        colorVariant.obj = pdpColorVariantCache[colorVariant.id];
      }
    });
  });
};
export const ProductDetailsPageTastic = ({
  data
}) => {
  const router = useRouter();
  const [product, setProduct] = useState<Product>(data?.data?.dataSource?.product);
  const econdaWidgetConfiguration: EcondaWidgetConfiguration = data?.econdaWidgetConfiguration?.dataSource;
  const variant = useMemo<Variant>(() => product?.variants.find(({
    attributes
  }: Variant) => attributes?.Sizing === router.query.size) || product?.variants[0], [product]);
  useEffect(() => {
    const product = data?.data?.dataSource?.product;
    runVariantsAgainstCache(product);
    setProduct(product);
  }, [data?.data?.dataSource?.product.productId]);
  return <ProductDetailsPage product={product} variant={variant} sizesLabel={data.sizesLabel} sizesUnavailableMessage={data.sizesUnavailableMessage} sizesModalHeadline={data.sizesModalHeadline} addToCartBtnLabel={data.addToCartBtnLabel} shippingCostsNote={data.shippingCostsNote} notAvailableModalText={data.notAvailableModalText} textSize={data.textSize} outfitSliderHeadline={data.outfitSliderHeadline} lastSeenSliderHeadline={data.lastSeenSliderHeadline} alternativeSliderHeadline={data.alternativeSliderHeadline} matchingSliderHeadline={data.matchingSliderHeadline} usps={data.shopUsps} fallbackIcon={data.fallbackIcon} econdaWidgetConfiguration={econdaWidgetConfiguration} availabilityModalContent={{
    icon: data.storeAvailabilityIcon,
    label: data.storeAvailabilityLabel,
    title: data.storeAvailabilityTitle,
    searchPlaceholder: data.storeAvailabilitySearchPlaceholder,
    selectPlaceholder: data.storeAvailabilitySelectPlaceholder,
    emptySearchError: data.storeAvailabilityEmptySearch,
    noResultsError: data.storeAvailabilityNoResults,
    note: data.storeAvailabilityNote,
    storeClosedHint: data.storeAvailabilityStoreClosed,
    notListedHint: data.storeAvailabilityNotListed,
    availableLabel: data.storeAvailabilityAvailable,
    onlineOnlyLabel: data.storeAvailabilityOnlineOnly,
    notAvailableLabel: data.storeAvailabilityNotAvailable,
    appointmentLabel: data.storeAvailabilityAppointmentLabel,
    locationLabel: data.storeAvailabilityLocationLabel
  }} sizeAdvisorLabel={data.sizeAdvisorLabel} sizeAdvisorTables={data.sizeAdvisorTables} sizeDeviationLabel={data.sizeDeviationLabel} sizeDeviationIcon={data.sizeDeviationIcon} sizeDeviationTexts={data.sizeDeviationTexts} notBuyableText={data.notBuyableText} fallbackImage={data?.data?.dataSource?.fallBackImage} data-sentry-element="ProductDetailsPage" data-sentry-component="ProductDetailsPageTastic" data-sentry-source-file="index.tsx" />;
};